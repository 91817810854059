import head from 'lodash/head'

export const fileTypePattern = /\.[^.\s]+$/
const fileNameFromPathPattern = /[^\\/]+?(?=\.\w+$)/
export const maxByteSize = 1024 * 1024 * 20
export const maxFileCount = 5
export const maxByteSizeForDm = 150 * 1024 * 1024

export const getFileNameFromPath = (path: string): string => {
  const matched = path.match(fileNameFromPathPattern)
  return head(matched) ?? ''
}
